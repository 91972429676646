div.simulation {
    padding-top: calc(8vh + 100px);
    padding: 8% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100vh;
    color: #29394D;
}

div.simulation h1 {
    font-weight: 300;
    font-size: 30px;
}

div.simulation .spinner-border {
    color: #4071ED;
    width: 40px; 
    height: 40px; 
    margin-bottom: 20px;
}
