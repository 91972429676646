@font-face {
    font-family: "IBMPlex";
    font-weight: 400;
    src: url("./IBMPlexSans-Regular.ttf");
}

@font-face {
    font-family: "IBMPlex";
    font-weight: 300;
    src: url("./IBMPlexSans-Light.ttf");
}

@font-face {
    font-family: "Roboto";
    font-weight: 300;
    src: url("./Roboto-Light.ttf");
}

@font-face {
    font-family: "Roboto";
    font-weight: 400;
    src: url("./Roboto-Regular.ttf");
}

@font-face {
    font-family: "Roboto";
    font-weight: 500;
    src: url("./Roboto-Medium.ttf");
}

@font-face {
    font-family: "Roboto";
    font-weight: 700;
    src: url("./Roboto-Bold.ttf");
}

